//require('./bootstrap');
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

import $ from 'jquery';
window.$ = window.jQuery = $;

$(document).ready(function () {
    AOS.init();
    const navLinks = document.querySelectorAll('.nav-item-mv')
    navLinks.forEach((l) => {
        l.addEventListener('click', () => {
            console.log("Click!");
            $('#burger-menu-wrapper > label').click();
        })
    });

    $("#contact-form input, #contact-form textarea").keyup(function(e) {
        console.log("keyup");
        if($(this).val() != ''){
            $(this).parent().find('.input-label').show();
        } else {
            $(this).parent().find('.input-label').hide();
        }
    });
});
